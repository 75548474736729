

#openToogle{
    background-color: white;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 50;
    margin-top: 1rem;
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
}
#openToogle2{
    background-color: white;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 50;
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    
}
/* Initial styling for the icon */
.menu-icon {
    display: none; /* Initially hide the icon */
  }
  
  /* Media query for mobile view */
  @media only screen and (max-width: 600px) {
    .menu-icon {
      display: block; /* Show the icon on screens with a width up to 600px */
      /* Additional styling for mobile view if needed */
    }
  }
  

#openToogle3{
    background-color: white;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 50;
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    margin-left: 2.5rem;
}
@media screen and (max-width: 425px){
    #button-toggle{
        margin-left: 4rem;
        margin-top:1rem;
    }
}
@media screen and (max-width: 320px){
    #button-toggle{
        margin-left: 5rem;
    }
}
