.contact-form{
    padding: 0 3rem 0 3rem;
    display: flex;
    
   
    margin-top: 4rem;
      /* scroll */
  /* padding-top: 3rem; */
}


.c-right{
    display: flex;
    justify-content: center;
    position: relative;
    flex: 1;
    margin-left: 7rem;
    width: 70%;
}

.c-right>form
{
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    /* align-items: center; */
    
}

.c-right .user{
    width: 16rem;
    height: 2rem;
    padding: 0.3em;
    outline: none;
    border: 2px solid var(--orange);
    border-radius: 8px;
    font-size: 16px;   
}

textarea{
    height: 4rem!important;
}

.c-blur1{
    top: 1rem;
    left: 8rem;
}

@media screen and (max-width: 768px) {
    .contact-form{
        padding: 0;
        flex-direction: column;
        gap: 1rem;
        height: 40rem;
    }
    .c-right .user{
        width: 16rem;
    }
  
} 

.user1{
    width: 16rem;
    height: 2rem;
    padding: 0.3em;
    outline: none;
    border: 2px solid var(--orange);
    border-radius: 8px;
    font-size: 16px; 
    
}
@media screen and (max-width: 480px){
   .user1{
     width: 16rem;
   }
   .contact-form{
    margin-bottom: -15rem;
   }
   .w-left span{
    font-size: 60px;
   }
   /* .mail-part{
    justify-content: center;
   } */
   .call-part{
    justify-content: center;
   }
}
.choice{
    display: flex;
    justify-content: space-evenly;
}
input[type=radio] {
    border: 2px solid var(--orange) !important;
    width: 5%;
    /* height: 1em; */
    
}


.logo{
    display: inline-block;
    padding-top: 5px;
    justify-content: center;
}
.logo2{
    display: inline-block;
    padding-top: 5px;
}
.mail-part{
    display: flex;
    /* justify-content: center; */
    gap: 2rem;
    
}
.call-part{
    display: flex;
    /* justify-content: center; */
    gap: 2rem;
    
}
.address-container{
    margin-top: 2rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    /* align-items: flex-start; */
}
.address-part{
    display: flex;
    /* justify-content: center; */
    gap: 2rem;
    
}
/* .address-part h3 {
    width: 50%;
} */
@media screen and (max-width: 1024px) {
    .address-part h3 {
        width: 100%;
    }
    .contact-form{
        flex-direction: column;
        align-items: center;
    }
    .c-right{
        margin-top: 2rem;
        margin-left: 0;
    }
    
  
}
.contact-container{
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
    /* justify-content: flex-start; */
}

 .c-right{
    box-shadow: 5px 10px 18px #888888;
    padding: 15px;
    background: rgb(238,174,202);
    background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
    border-radius: 5px;
 }
 
 @media screen and (max-width: 425px){
    .c-right{
        width: 100% !important;
    }
    .address-part h3{
        font-size: 15px;
    }
    .address-part{
        gap: 1rem  !important;
        margin-left: 5rem !important;
        width: 66% !important;
    }
    .address-part .logo2{
        margin-top: 1rem !important;
    }
    .call-part{
        margin-left: -4.5rem;
    }
    .mail-part{
        margin-left: -8.5rem;
    }
    .contact-form{
        margin-top: 0;
    }
 }

 

