.n-wrapper {
  height: 10vh;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  top: 0;
}
.n-left {
  flex: 1;
  align-items: center;
  justify-content: left;
  display: flex;
  gap: 2rem;
}


.n-name {
  font-size: 1.3rem;
  font-weight: bold;
}
.n-right {
  /* flex: 1; */
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 400;
}

.n-list {
  flex: 10;
}
@media screen and (max-width: 425px) {
  .button{
    margin-right: -16px !important;
    padding: 5px 9px 5px 9px !important;
  }
}
/* mega menu  */
.nav-dropdown-tile {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 10px;
  cursor: pointer;
}

@media screen and (max-width: 1050px) {
  /* .n-list{
    display: none;
  } */
  .navbar-menu{
    display: flex;
    position: absolute;
  }


  
}


.navbar-menu{
  margin-left:1rem;
  display:none;
  position: relative;
}

.navbar-menu-container{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  text-align: end;
  padding: 2rem;
  position: absolute;
  top: 40px;
  right: 0;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0 0 5 rgba(0,0,0,0.2);
}

.navbar-menu-container li{
  margin: 1rem 0;
}







