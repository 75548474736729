@media screen and (max-width: 1024px){
    #image1{
        height: 40% !important;
        width: 90% !important;
    }
  }

#VirtualX{
    background: rgb(50, 218, 218);
    opacity: 0.8;

}
@media screen and (max-width: 768px){
    #image1{
        height: 250px !important;
        width: 450px !important;
    }
}
 